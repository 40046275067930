import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PaymentSuccessSettings from '../../campaigns/campaign-form/shared/payment-success-settings';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FieldError from '../../../../shared/fields-errors/field-error';
import ProgramsOptions from './shared/programs-options';
import axios from 'axios';
import { ADD_EDIT_PROGRAM, GET_PROGRAM_BY_ID } from '../../../../constants/endpoints';
import { Form } from 'react-bootstrap';
import { months } from '../../../../data-list/month.llist';
import { getSetOrgInfo } from '../../../../utils/user-info';
import UploadImageDialog from '../../../../shared/upload-image-dialog/upload-image-dialog';
import useUploadFile from '../../../../hooks/upload-file.hook';
import DonorCommentsForm from '../../campaigns/campaign-form/shared/donor-comments-form';
import ProgramsAdditionalOptions from './shared/programs-additional-options';
import RecurringOptions from '../../campaigns/campaign-form/shared/recurring-options';


const ProgramsForm = React.forwardRef((props, ref) => {

    const navigate = useNavigate();
    const [paymentTypeList, setPaymentTypeList] = useState(['Free', 'Paid', 'Both']);
    const [programTypeList, setProgramTypeList] = useState(['Itikaf']);
    const [defaultIntervalList, setDefaultIntervalList] = useState([]);
    const [searchParams] = useSearchParams();
    const programID = searchParams.get('id');



    const { uploadImage } = useUploadFile();
    const childRef = useRef(null);
    const uploadImageDialogRef = useRef(null);
    const [fileName, setFileName] = useState("");
    const [currentFile, setCurrentFile] = useState(null);
    const [programLoader, setProgramLoader] = useState(false);

    const [currentProgram, setCurrentProgram] = useState("");
    const methods = useForm({
        defaultValues: {
            is_days_in_itikaf: true,
            is_email: true,
            is_phone_number: true,
            is_last_name: true,
            is_first_name: true,
            program_type: 'Itikaf'
        }
    });
    const { register, control, setValue, trigger, handleSubmit, unregister, reset, formState: { isValid, errors }, getValues, watch } = methods;
    const watchAllFields = watch();
    const organizationInfo = getSetOrgInfo();

    const cancelCreateProgram = () => {
        navigate("/programs");
    }

    const addUpdateProgram = async (formValues, isPreview) => {
        console.log(formValues);
    }

    const updateAndPreview = () => {
        if (!isValid) {
            trigger();
        } else {
            addUpdateProgram(getValues(), true)
        }
    }

    const previewChanges = () => {
        window.open(`
                ${organizationInfo?.organization_short_name ?
                organizationInfo?.organization_short_name : 'masjid'
            }/program/${currentProgram?.donation_page_link}`, '_blank').focus();
    }

    const getProgramByID = () => {
        axios.get(GET_PROGRAM_BY_ID + searchParams.get('id'))
            .then((res) => {
                if (res?.data?.status) {
                    setCurrentProgram(res.data.response);
                    setFileName(res?.data?.response?.image_alt_name);
                    res.data.response.unit_label = res.data.response.unit_label ? res.data.response.unit_label : 'Unit';
                    reset(res.data.response);
                }
            }).catch((error) => {

            })
    }

    useImperativeHandle(ref, () => {
        return {
            previewChanges: previewChanges
        };
    });

    const setColor = (event) => {
        document.getElementById('txtColor').value = event.target.value;
    }

    useEffect(() => {
        if (searchParams.get('id')) {
            getProgramByID();
        } else {
            // setValue('google_apple_pay', true);
            // setValue('unit_label', 'Unit');
        }
    }, []);

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(addUpdateProgram)}
                    className='col-xl-5 col-xl-55 col-md-12 col-sm-12 col-xs-12 left-form-panel p-0'>
                    <div className='form-title text-white d-flex justify-content-start align-items-center bg-blue p-3'>
                        <a onClick={cancelCreateProgram}
                            className='text-white cursor-pointer'><i className="fa-solid fa-chevron-left me-2"></i></a>
                        <h5 className='text-white mb-0'>Program</h5>
                    </div>

                    <div className='campaign-form-body p-4'>
                        <div className='col-12 card'>
                            <div className='card-body'>
                                <div className="form-group"><label className="form-control-label text-black text-bold" >
                                    Program Detail
                                </label>

                                    <div className="form-group mt-3"><label className="" >
                                        <span className='form-control-label text-black'>Program Title <span className='text-danger'>*</span></span>
                                    </label>
                                        <input
                                            {...register("program_title", { required: true })}
                                            placeholder="Program Title"
                                            type="text" className="form-control-alternative form-control" />
                                    </div>
                                    {errors?.program_title && errors?.program_title?.type === 'required' ? <FieldError message={'Please fill out this field'} /> : ''}

                                    <div className="form-group mt-3"><label className="" >
                                        <span className='form-control-label text-black'>Program Description</span>
                                    </label>
                                        <input
                                            {...register("program_description")}
                                            placeholder="Program Title"
                                            type="text" className="form-control-alternative form-control" />
                                    </div>
{/* 
                                    <div className="form-group mt-3"><label className="" >
                                        <span className='form-control-label text-black'>Program Type</span>
                                    </label>
                                        <select {...register("program_type", {
                                            validate: {
                                                required: (value) => {
                                                    if (!value) return "Program Type is required";
                                                    return true
                                                }
                                            }
                                        })} className="form-control" placeholder='Program Type'>
                                            {
                                                programTypeList.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }

                                        </select>
                                        {errors?.program_type ?
                                            <FieldError message={errors?.program_type?.message} /> : ''}
                                    </div> */}
                                    <div className="form-group mt-3">
                                        {
                                            fileName ?
                                                <>
                                                    <span className='f-16 text-ma-primary mt-2'>{fileName}</span>
                                                    <a
                                                        onClick={() => {
                                                            if (currentFile) {
                                                                uploadImageDialogRef?.current?.setImageSrcByFile(currentFile);
                                                            }
                                                        }}
                                                        className='mx-2 anchor cursor-pointer'>Change Banner Image</a>
                                                </>
                                                :
                                                    <p>
                                                        <button type='button' onClick={() => {
                                                            uploadImageDialogRef?.current?.handleShow()
                                                        }}
                                                        className='btn btn-primary mb-0 w-100'>
                                                        Upload Banner Image
                                                    </button>
                                                    </p>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                        <ProgramsOptions ref={childRef} selectedProgram={currentProgram} />


                        {watchAllFields?.program_type == 'Itikaf' ?

                            <ProgramsAdditionalOptions ref={childRef} selectedProgram={currentProgram} />
                            : ''
                        }


                        <div className='col-12 card mt-3'>
                            <div className='card-body'>
                                <div className="form-group"><label className="form-control-label text-black text-bold" >
                                    Program Amount
                                </label>

                                    <div className="form-group mt-3"><label className="" >
                                        <span className='form-control-label text-black'>Payment Type</span>
                                    </label>

                                        <select {...register("payment_type", {
                                            validate: {
                                                required: (value) => {
                                                    if (!value) return "The field is required";
                                                    return true
                                                }
                                            }
                                        })} className="form-control" placeholder='Payment Type'>
                                            {
                                                paymentTypeList.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }

                                        </select>
                                        {errors?.payment_type ?
                                            <FieldError message={errors?.payment_type?.message} /> : ''}
                                    </div>

                                </div>
                            </div>
                        </div>


                        {


                            watchAllFields?.payment_type == "Paid" || watchAllFields?.payment_type == "Both" ?
                                <div className='col-12 card mt-3'>
                                    <div className='card-body'>
                                        <div className='mt-1'>
                                            <span className='text-black text-bold'>Payment Intervals</span>

                                            <div>
                                                <input
                                                    {...register("one_time_interval", {
                                                        validate: {
                                                            required: (value) => {
                                                                if (!value && watchAllFields?.monthly_interval === false) return "This field is required";
                                                                return true
                                                            }
                                                        },
                                                        onChange: (event) => {
                                                            const list = defaultIntervalList;
                                                            const index = list.indexOf("One-Time");
                                                            if (event?.target?.checked) {
                                                                if (index === -1) {
                                                                    list.push("One-Time")
                                                                }
                                                            } else {
                                                                if (index !== -1) {
                                                                    list.splice(index, 1);
                                                                }
                                                            }

                                                            setDefaultIntervalList(list)
                                                        }
                                                    })}

                                                    className="me-1 mt-2" type="checkbox" />
                                                <label>
                                                    One-time
                                                </label>
                                                {watchAllFields?.one_time_interval === true ?
                                                    <>
                                                        <div className="form-group"><label className="form-control-label text-black mt-2" >
                                                            One-Time Amount
                                                        </label><input
                                                                {...register("one_time_amount", {
                                                                    validate: {
                                                                        required: value => {
                                                                            if (!value) return 'Amount is required';
                                                                            return true;
                                                                        },
                                                                        min: value => {
                                                                            if (value <= 0) {
                                                                                return `Amount cannot be less then or equal to 0`
                                                                            };
                                                                            return true;
                                                                        }
                                                                    }
                                                                })}
                                                                placeholder="Amount"
                                                                type="number" className="form-control-alternative form-control" /></div>
                                                        {errors?.one_time_amount ? <FieldError message={errors?.one_time_amount?.message} /> : ''}
                                                    </>
                                                    : ""}
                                            </div>


{/* 
                                            <div className='mt-1'>
                                                <input
                                                    {...register("monthly_interval", {

                                                        validate: {
                                                            required: (value) => {
                                                                if (!value && watchAllFields?.one_time_interval === false) return "This field is required";
                                                                return true
                                                            }
                                                        },
                                                        onChange: (event) => {
                                                            const list = defaultIntervalList;
                                                            const index = list.indexOf("Monthly");
                                                            if (event?.target?.checked) {
                                                                if (index === -1) {
                                                                    list.push("Monthly")
                                                                }
                                                            } else {
                                                                if (index !== -1) {
                                                                    list.splice(index, 1);
                                                                }
                                                            }

                                                            setDefaultIntervalList(list)
                                                        }
                                                    })}

                                                    className="me-1" type="checkbox" />
                                                <label>
                                                    Monthly
                                                </label>
                                                {watchAllFields?.monthly_interval === true ?
                                                    <>
                                                        <div className="form-group"><label className="form-control-label text-black mt-2" >
                                                            Monthly Amount
                                                        </label><input
                                                                {...register("monthly_amount", {
                                                                    validate: {
                                                                        required: value => {
                                                                            if (!value) return 'Amount is required';
                                                                            return true;
                                                                        },
                                                                        min: value => {
                                                                            if (value <= 0) {
                                                                                return `Amount cannot be less then or equal to 0`
                                                                            };
                                                                            return true;
                                                                        }
                                                                    }
                                                                })}
                                                                placeholder="Amount"
                                                                type="number" className="form-control-alternative form-control" /></div>
                                                        {errors?.monthly_amount ? <FieldError message={errors?.monthly_amount?.message} /> : ''}
                                                    </>
                                                    : ""}

                                            </div>
                                            {(errors?.one_time_interval || errors?.monthly_interval) ?
                                                <FieldError message={(errors?.one_time_interval ? errors?.one_time_interval : errors?.monthly_interval)?.message} /> : ''} */}
     {(errors?.one_time_interval || errors?.monthly_interval) ?
                                                <FieldError message={(errors?.one_time_interval ? errors?.one_time_interval : errors?.monthly_interval)?.message} /> : ''}


                                            <div className="form-group mt-3"><label className="" >
                                                <span className='form-control-label text-black'>Set as Default</span>
                                                <p className='mb-0'>The default interval will be pre-selected</p>
                                            </label>

                                                <select {...register("default_payment_interval", {
                                                    validate: {
                                                        required: (value) => {
                                                            if (!value) return "Please select default payment interval";
                                                            return true
                                                        }
                                                    }
                                                })} className="form-control" placeholder='Set as Default'>
                                                    {
                                                        defaultIntervalList.map((item, index) => {
                                                            return <option key={index} value={item}>{item}</option>
                                                        })
                                                    }

                                                </select>
                                                {errors?.default_interval ?
                                                    <FieldError message={errors?.default_interval?.message} /> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }



                        {/* processing fee start */}
                        <div className='col-12 card mt-3'>
                            <div className='card-body'>
                                <label className="form-control-label text-black text-bold">
                                    Processing Fee
                                </label>

                                <div className='form-group mt-3'>
                                    <label className="form-control-label d-flex align-items-center">
                                        <input
                                            {...register(`ask_subscriber_to_cover_fee`)}
                                            className="me-1" type="checkbox" /> Ask subscribers to cover fee
                                    </label>
                                </div>
                                <div className='form-group mt-2'>
                                    <label className="form-control-label d-flex align-items-center">
                                        <input
                                            {...register(`ask_subscriber_to_cover_fee_default`)}
                                            className="me-1" type="checkbox" /> Ask subscribers to cover fees by default
                                    </label>
                                </div>

                            </div>
                        </div>
                        {/* processing fee end */}




                        {/* consent start  */}
                        <div className='col-12 card mt-3'>
                            <div className='card-body'>
                                <label className="form-control-label text-black text-bold">
                                    Consent Fields
                                </label>
                                <div className='form-group mt-2'>
                                    <label className="form-control-label d-flex align-items-center">
                                        <input
                                            {...register(`is_terms_conditions`)}
                                            className="me-1" type="checkbox" /> Terms of Condition
                                    </label>
                                </div>

                                <div className="form-group mt-3"><label className="form-control-label text-black" >
                                    Link to Privacy Policy
                                </label>
                                    <input
                                        {...register("link_to_privacy")}
                                        placeholder="Link to Privacy Policy"
                                        type="text" className="form-control-alternative form-control mt-1" />
                                </div>



                            </div>
                        </div>
                        {/* consent end  */}


                        {/* compliance and disclaimer start  */}
                        <div className='col-12 card mt-3'>
                            <div className='card-body'>
                                <label className="form-control-label text-black text-bold">
                                    Compliance and Disclaimer
                                </label>

                                <div className="form-group mt-3">
                                    <textarea
                                        {...register(`compliance_disclaimer`)}
                                        rows={2} placeholder="Enter your tax status, if applicable" className="form-control-alternative form-control" ></textarea>
                                </div>

                            </div>
                        </div>
                        {/* compliance and disclaimer end  */}


                        {/* payment success message view start */}
                        <PaymentSuccessSettings watchAllFields={watchAllFields} isProgram={true} />
                        {/* payment success message view end */}
                    </div>

                    <div className='campaign-form-footer'>
                        <button onClick={cancelCreateProgram} disabled={programLoader} type="button"
                            className="btn btn-outline-secondary mx-3">Cancel</button>
                        <div>
                            <button disabled={programLoader} type="submit"
                                className={`btn btn-ma-primary btn-md me-3 ${programLoader ? 'button--loading position-relative ' : ''}`}>
                                {programID ? 'Update' : 'Add'}
                            </button>
                            {
                                programID ?
                                    <button disabled={programLoader} type="button"
                                        onClick={updateAndPreview}
                                        className={`btn btn-ma-primary btn-md me-3 ${programLoader ? 'button--loading position-relative ' : ''}`}>
                                        Update & Preview
                                    </button> : ''
                            }
                        </div>
                    </div>
                </form>
            </FormProvider>
            {/* //upload image dialog start */}
            <UploadImageDialog
                ref={uploadImageDialogRef}
                setFileName={setFileName}
                currentValue={currentProgram}
                setCurrentFile={setCurrentFile} />
            {/* //upload image dialog end */}
        </>
    );
});

export default ProgramsForm;