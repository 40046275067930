import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ADD_OFFLINE_DONATION, FIND_DONOR_BY_EMAIL, GET_CAMPAIGN_BY_ID, GET_DONATION_BY_ID, GET_PRODUCT_BY_ID } from '../../../constants/endpoints';
import DonorPersonalInfo from './sub-components/donor-personal-info';
import DonorAddressInfo from './sub-components/donor-address-info';
import OfflineDonationInfo from './sub-components/offline-donation-info';
import { FormProvider, useForm } from 'react-hook-form';
import getFormattedDate from '../../../utils/date-format';

function OfflineDonations(props) {
    const params = useParams();
    const donationType = params.type;
    const donationID = params.donation_id;
    const navigate = useNavigate();
    const [isProductAvailable, setIsProductAvailable] = useState(true);
    const showExistingDonorDialogRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [isDonorExists, setIsDonorExists] = useState('not_exist');
    const [existingDonorInfoModel, setExistingDonorInfoModel] = useState(null);

    const [requestLoader, setRequestLoader] = useState(false);
    const [currentEmail, setCurrentEmail] = useState('');
    const [currentCampaign, setCurrentCampaign] = useState({});
    //finding existing donor start
    const findDonorByEmail = (email) => {
        setCurrentEmail(email);
        if(email !== currentEmail){
        setRequestLoader(true);
        setIsDonorExists(false);
        axios.post(FIND_DONOR_BY_EMAIL, { email: email })
            .then((res) => {
                setRequestLoader(false);
                if (res?.data?.status && res?.data?.response?.is_exists) {
                    setExistingDonorInfoModel(res?.data?.response);
                    setIsDonorExists('exists');
                } else {
                    const formCopy = structuredClone(getValues());
                    reset({
                        ...{donation_info:formCopy.donation_info},
                        ...{donor_info:{
                            first_name:formCopy.donor_info.first_name,
                            last_name:formCopy.donor_info.last_name,
                            email:formCopy.donor_info.email,
                            address:'',
                            address_2:'',
                            city:'',
                            state_province:'',
                            postal_code:'',
                            country:'',
                            phone:''
                        }}
                    });

                    console.log(getValues())
                    setIsDonorExists(false);
                }
            }).catch((error) => {
                setRequestLoader(false);
                setIsDonorExists(false);
            })
        }
        
    }
    //finding existing donor end

    //getting current campaign start
    const getCampaignByID = () => {
        const endpoint = (donationType === 'product' ? GET_PRODUCT_BY_ID : GET_CAMPAIGN_BY_ID)+ params?.id
        axios.get(endpoint)
        .then((res) => {
            if(res?.data?.status){
                setCurrentCampaign(res?.data?.response);
                if(!res?.data?.response?.sell_after_out_of_stock){
                    if(res?.data?.response?.available_units === undefined){
                        setIsProductAvailable(true);
                    }else if(res?.data?.response?.available_units <= 0){
                        setIsProductAvailable(false);
                    }
                }else{
                    setIsProductAvailable(true)
                }
            }
        }).catch((error) => {

        })
    }
    //getting current campaign end
    const methods = useForm();
    const { reset, getValues, setValue, formState: {isValid, errors } } = methods;

    const addOfflineDonation = (formValues) => {
        console.log(formValues)
        const formData = structuredClone(formValues);
        formData.donation_info.campaign_id = params.id;
        // formData.donation_info.created_at = formData.donation_info.created_at + ' 00:00:00'
        formData.donation_info.created_at = formData.donation_info.created_at;
        formData.donation_info.platform_used = currentDonation?.platform_used
        setRequestLoader(true);
        axios.post(ADD_OFFLINE_DONATION, formData)
        .then((res) => {
            setRequestLoader(false);
            if(res?.data?.status){
                navigate('/donations');
            }else{
                setErrorMessage(res?.data?.message ? res?.data?.message : `Failed to save ${currentDonation?.platform_used} donation.`)
            }
        }).catch((error) => {
            setErrorMessage(`Failed to save ${currentDonation?.platform_used} donation.`)
            setRequestLoader(false);
        })
    }

    const loadExistingDonor = () => {
        const fieldsCopy = structuredClone(getValues());
        reset({
            ...fieldsCopy,
            ...{
                donor_info: existingDonorInfoModel
            }
        });

        setIsDonorExists('use_exists');
        console.log(getValues())
    }

    useEffect(() => {
        getCampaignByID()
    },[])

    //implementing update method for offline donation
    const [currentDonation, setCurrentDonation] = useState({});
    const getDonationByID = () => {
        setRequestLoader(true);
        axios.get(GET_DONATION_BY_ID + donationID)
            .then((res) => {
                setRequestLoader(false);
                if (res?.data?.status) {
                   setCurrentDonation(res?.data?.response);
                }

            }).catch((error) => {
                setRequestLoader(false);
            });
    }

    useEffect(() => {
        if(donationID){
            getDonationByID();
        }
    }, [donationID]);

    useEffect(() => {
        if(currentDonation?.id){
            reset({
                donation_info:{
                    id: currentDonation?.id,
                    payment_type: currentDonation?.payment_type,
                    created_at: getFormattedDate(currentDonation?.created_at, true),
                    amount: currentDonation?.amount / 100,
                    donation_designation: currentDonation?.donation_designation,
                    no_of_units: parseFloat(currentDonation?.no_of_units)
                },
                donor_info:{
                    id: currentDonation?.donor_id,
                    first_name:currentDonation.first_name,
                    last_name:currentDonation.last_name,
                    email:currentDonation.email,
                    address:currentDonation?.address,
                    city:currentDonation?.city,
                    state_province:currentDonation?.state_province,
                    postal_code:currentDonation?.postal_code,
                    country:currentDonation?.country,
                    phone:currentDonation?.phone
                }
            });
        }
    }, [currentDonation])

    return (
        <>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(addOfflineDonation)}>
            <div className='row'>
                <div>
                    <h5 className='font-weight-semibold text-black'>{donationID ? 'Update' : 'Add'} Donation</h5>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <div className='row mb-3'>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <div className='col-12 border-bottom mb-3'>
                                    <div className='d-flex justify-content-between'>
                                        <h6 className='text-bold'>Personal Info</h6>
                                        <p className='mb-0 f-xsmal text-capitalize'>Selected {donationType} : <span className='text-black text-bold'>{params.name}</span></p>
                                    </div>
                                </div>
                                {
                                    !isProductAvailable && donationType === 'product' ? 
                                <p className='mb-0 text-bold text-danger text-center'>
                                            This product is out of stock kindly increase the goal or enable "continue selling after out of stock" check in product settings
                                        </p> : ''
                                }
                                {/* donor personal info start */}
                                <DonorPersonalInfo
                                loadExistingDonor={loadExistingDonor}
                                    isDonorExists={isDonorExists}
                                    findDonorByEmail={findDonorByEmail}
                                />
                                {/* donor personal info end */}

                                {/* donor address info start */}
                                <DonorAddressInfo />
                                {/* donor address info end */}

                            </div>

                            <div className='mt-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <div className='col-12 border-bottom mb-3'>
                                    <h6 className='text-bold'>Donation Info</h6>
                                </div>

                                {/* donation info */}
                                <OfflineDonationInfo
                                donationType={donationType}
                                currentCampaign={currentCampaign} />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                            <p className='text-danger mb-0'>{errorMessage}</p>
                        </div>
                        <div className='col-lg-6 col-md-12 text-right'>
                        {
                            !isProductAvailable && donationType === 'product' ? 
                            <button onClick={() => navigate(-1)}
                            type="button"
                            className={`btn btn-ma-primary btn-md`}>
                            Close
                        </button> :  <button
                            disabled={isDonorExists === 'exists' || requestLoader}
                            type="submit"
                            className={`btn btn-ma-primary btn-md position-relative ${requestLoader ? 'button--loading' : ''}`}>
                            {donationID ? 'Update' : 'Add'} Donation
                        </button>
                        }
                       
                    </div>
                    </div>
                    
                </div>
                {/* ${requestLoader ? 'button--loading' : ''} */}

            </div>
            </form>
            
            </FormProvider>
        </>
    );
}

export default OfflineDonations;