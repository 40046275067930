import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../../../shared/fields-errors/field-error';
import { Button } from 'react-bootstrap';
import CampaignIconSelection from '../../../campaigns/campaign-form/shared/campaign-icon-selection';
import { ENVIRONMENT_CONSTANT } from '../../../../../constants/env.constant';

const ProgramsAdditionalOptions = React.forwardRef((props, ref) => {
    

    const { register, formState: { errors } } = useFormContext();

    return (
        <>
        <div className='col-12 card mt-3'>
            <div className='card-body'>
            <label className="form-control-label text-black text-bold" >
                            Additional Information
                        </label>

                <div className='mt-2'>
                    <label className="form-control-label d-flex align-items-center" >
                        <input {...register("is_vehicle_detail", {
                        })} className='me-1' type="checkbox" /> Vehicle Details
                    </label>
                </div>
                <div className='mt-2'>
                    <label className="form-control-label d-flex align-items-center" >
                        <input {...register("is_emergency_contact", {
                        })} className='me-1' type="checkbox" /> Emergency Contact
                    </label>
                </div>
                <div className='mt-2'>
                    <label className="form-control-label d-flex align-items-center" >
                        <input {...register("is_days_in_itikaf", {required: {
                                value: true,
                                message: "(Itikaf field is required)"
                            }})}
                         className='me-1' type="checkbox" /> Days in Itikaf &nbsp; {errors?.is_days_in_itikaf ? <FieldError message={errors?.is_days_in_itikaf?.message} /> : ''}
                    </label>
                </div>

            </div>
        </div>
        </>
        
    );
});

export default ProgramsAdditionalOptions;