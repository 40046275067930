import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import FieldError from '../../../../shared/fields-errors/field-error';
import axios from 'axios';
import { REGISTER_DEVICE, UPDATE_DEVICE } from '../../../../constants/endpoints';
import { KIOSK_LAYOUT_LIST } from '../../../../data-list/data-list';
import { useGetCampaigns } from '../../../../hooks/get-campaign';
import Select from 'react-select';

const AddDeviceModal = React.forwardRef((props, ref) => {

    const inputRef = useRef(null);

    const [show, setShow] = useState(false);
    const [registerDeviceLoader, setRegisterDeviceLoader] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [togglePasswordField, setTogglePasswordField] = useState(true);
    

    useEffect(() => {
        if (show && inputRef.current) {
          inputRef.current.focus();
        }
    }, [show]);

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow,
            handleClose: handleClose,
            openEditView:openEditView
        };
    });

    const handleClose = () => {
        setTogglePasswordField(true);
        reset({
            device_code: '',
            device_name: '',
            device_password: '',
            campaign_id: ""
        });
        setShow(false);
        setIsEdit(false);
    };
    const handleShow = () => {
        setShow(true);
    }

    const methods = useForm();
    const { register, handleSubmit, setFocus, reset, formState: { errors }, watch } = methods;
    const fields = watch();

    const [updateData, setUpdateData] = useState({});
    const openEditView = (formValues) => {
        setUpdateData(formValues);
        const form = {
            device_code: formValues.device_code,
            device_name: formValues.device_name,
            device_password: formValues.device_password,
            device_layout: formValues.device_layout,
            campaign_id: formValues?.campaign_id
        }
        setIsEdit(true);
        handleShow();
        reset(form);
    }

    useEffect(() => {
        setFocus("device_code");
      }, [setFocus, show]);
    
    const registerDevice = (formValues) => {
        setRegisterDeviceLoader(true);
        axios.post(REGISTER_DEVICE, formValues)
        .then((res) => {
            setRegisterDeviceLoader(false);
            if(res?.data?.status){
                handleClose();
                props?.getRegisteredDevices();
            }else{
                alert(res?.data?.response)
            }
        }).catch((error) => {
            setRegisterDeviceLoader(false);
            alert("Unable register device");
        });
    }

    const updateDevice = (values) => {
        setRegisterDeviceLoader(true);
        axios.post((UPDATE_DEVICE + updateData?.id + '/portal'), values)
        .then((res) => {
            setRegisterDeviceLoader(false);
            if(res?.data?.status){
                handleClose();
                props?.getRegisteredDevices(values);
            }else{
                alert('Unable update device');
            }
        }).catch((error) => {
            setRegisterDeviceLoader(false);
            alert("Unable update device");
        });
    }

    return (
        <>
            <Modal 
            keyboard={false} 
            backdrop="static" show={show} onHide={handleClose} animation={true} size='xl' >
            <Form onSubmit={handleSubmit(isEdit ? updateDevice : registerDevice)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Update Device' : 'Register Device'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <div className='row'>
                        <div className='col-6'>
                            <Form.Group>
                            <Form.Label>Device Code</Form.Label>
                            {/* ref={inputRef} */}
                            <Form.Control
                            {...register('device_code',{
                                disabled:isEdit,
                                validate:{
                                    required: value => {
                                        if (!value) return 'Field is required.';
                                       return true;            
                                   },
                                   minLength: value => {
                                       if (value.length < 6) {
                                           return `Invalid device code.`
                                       };
                                       return true;            
                                   }
                                }
                            })} className='device-code-input' maxLength={6}
                            type="text" placeholder="Enter code" />
                            <Form.Text className="text-muted">
                                Enter code that is displaying on your kiosk screen.
                            </Form.Text>
                        </Form.Group>
                        {errors?.device_code ? <FieldError message={errors?.device_code?.message} /> : ''}

                        <Form.Group className="mb-3" >
                            <Form.Label>Device Name</Form.Label>
                            <Form.Control 
                            {...register('device_name',{
                                validate:{
                                    required: value => {
                                        if (!value) return 'Field is required.';
                                        return true;
                                    }
                                }
                            })}
                            type="text"
                             placeholder="Enter device name" />
                             {errors?.device_name ? <FieldError message={errors?.device_name?.message} /> : ''}
                        </Form.Group>
                        
                    {
                        isEdit ? 
                        <>
                        <Form.Group className="mb-3" >
                            <Form.Label>Device Password</Form.Label>
                            <div className='input-group'>
                                        <input
                                        {...register('device_password',{
                                            validate:{
                                                required: (value) => {
                                                    if(!value) return "This field is required";
                                                    return true
                                                },
                                                minLength: (value) => {
                                                    if(value?.length <= 3) return "Minimum 4 characters is allowed."
                                                },
                                                maxLength: (value) => {
                                                    if(value?.length > 4) return "Maximum 4 characters is allowed."
                                                }
                                            }
                                        })}
                                            placeholder="Device Code"
                                            type={togglePasswordField ? 'password' : 'text'} 
                                            className="form-control-alternative form-control" />
                                             <i
                                             title={togglePasswordField ? 'Show' : 'Hide'} 
                                             onClick={() => {
                                                if(togglePasswordField){
                                                    setTogglePasswordField(false);
                                                }else{
                                                    setTogglePasswordField(true);
                                                }
                                             }} className={`input-group-text fa-solid ${togglePasswordField ? 'fa-lock' : 'fa-unlock'} cursor-pointer`}></i>
                                        </div>
                             {errors?.device_password ? <FieldError message={errors?.device_password?.message} /> : ''}
                        </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Layout</Form.Label>
                                        <div className='input-group'>
                                            <select {...register("device_layout")} className="form-control" placeholder='Set as Default'>
                                            {
                                                KIOSK_LAYOUT_LIST?.map((item, index) => {
                                                    return <option key={`layout_${index}`} 
                                                    value={item.value}>{item.label}</option>
                                                })
                                            }
                                            </select>
                                        </div>
                                    </Form.Group>
                                    {
                                        (fields?.device_layout === 'layout_3' || fields?.device_layout === 'layout_4') ? 
                                        <>
                                            <Form.Group className="mb-3">
                                        <Form.Label className='mb-0'>
                                            Select Campaign
                                            <p className="f-12-small text-warning">(Campaign is required for Layout 3 and Layout 4)</p>
                                        </Form.Label>
                                        <Controller
                                        control={methods.control}
                                        name={'campaign_id'}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "This field is required"
                                            }
                                        }}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Select
                                                isSearchable={true}
                                                inputRef={ref}
                                                options={props?.campaignList.map(it => {
                                                    return {value: it.id, label: it.campaign_title}
                                                })}
                                                value={props?.campaignList?.map(it => {
                                                    return {value: it.id, label: it.campaign_title}
                                                }).find(c => c.value === value)}
                                                onChange={val => {
                                                    onChange(val?.value)
                                                }}
                                            />
                                        )}
                                    />
                                        {
                                        errors?.campaign_id ? 
                                        <FieldError message={errors?.campaign_id?.message} /> : ''
                                    }
                                    </Form.Group>
                                        </> : ''
                                    }
                                        
                                    
                                    
                        </>
                         : ''
                    }
                            </div>
                            <div className='col-6 position-relative' style={{
                                backgroundColor:'#d3d3d3a6',
                                borderRadius:'5px'
                            }}>
                            <img className='img-fluid'
                            style={{
                                position:'absolute',
                                top:'50%',
                                left:'50%',
                                transform:'translate(-50%, -50%)'
                            }} 
                            src={`../img/masjid/device_layouts/${fields?.device_layout}.png`} />
                            </div>
                            
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button disabled={registerDeviceLoader} type='submit' variant="primary" 
                    className={`${registerDeviceLoader ? 'position-relative button--loading' : ''}`}>
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>
        </>

    );
});

export default AddDeviceModal;