import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../../../shared/fields-errors/field-error';
import { Button } from 'react-bootstrap';
import CampaignIconSelection from '../../../campaigns/campaign-form/shared/campaign-icon-selection';
import { ENVIRONMENT_CONSTANT } from '../../../../../constants/env.constant';

const ProgramsOptions = React.forwardRef((props, ref) => {
    

    const { register, formState: { errors } } = useFormContext();

    return (
        <>
        <div className='col-12 card mt-3'>
            <div className='card-body'>
            <label className="form-control-label text-black text-bold" >
                            Add Information
                        </label>

                <div className='mt-2'>
                    <label className="form-control-label d-flex align-items-center" >
                        <input {...register("is_first_name", {
                            required: {
                                value: true,
                                message: "(First name is required)"
                            }
                        })} className='me-1' type="checkbox" /> First Name &nbsp; {errors?.is_first_name ? <FieldError message={errors?.is_first_name?.message} /> : ''}
                    </label>
                </div>
                <div className='mt-2'>
                    <label className="form-control-label d-flex align-items-center" >
                        <input {...register("is_last_name", {
                            required: {
                                value: true,
                                message: "(Last name is required)"
                            }
                        })} className='me-1' type="checkbox" /> Last Name &nbsp; {errors?.is_last_name ? <FieldError message={errors?.is_last_name?.message} /> : ''}
                    </label>
                </div>
                <div className='mt-2'>
                    <label className="form-control-label d-flex align-items-center" >
                        <input {...register("is_phone_number",{required: {
                            value: true,
                            message: "(Phone number is required)"
                        }})}
                         className='me-1' type="checkbox" /> Phone Number &nbsp; {errors?.is_phone_number ? <FieldError message={errors?.is_phone_number?.message} /> : ''}
                    </label>
                </div>

                <div className='mt-1'>
                    <label className="form-control-label d-flex align-items-center">
                    <input {...register("is_email", {
                         required: {
                            value: true,
                            message: "(Email is required)"
                        }
                    })} className='me-1' type="checkbox" />Email &nbsp; {errors?.is_email ? <FieldError message={errors?.is_email?.message} /> : ''}
                    </label>
                    

                </div>
                <div className="form-group mt-1">
                    <label className="form-control-label d-flex align-items-center" >
                        <input
                            {...register('is_address')}
                            className="me-1" type="checkbox" /> Full Address
                    </label>
                </div>

            </div>
        </div>
        </>
        
    );
});

export default ProgramsOptions;