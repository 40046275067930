export const SidebarLinks = [
  {
    label: "Fundraise",
    icon:'fa-solid fa-hand-holding-dollar',
    role:2,
    links: [
      {
        label: "Campaigns",
        link: "/campaigns",
        role: 2
      },
      {
        label: "Memberships",
        link: "/memberships",
        role: 2
      },
      {
        label: "Products",
        link: "/products",
        role: 2
      },
      {
        label: "Programs/Events",
        link: "/programs",
        role: 2
      },
      {
        label: "Links",
        link: "/pages",
        role: 2
      },
      // {
      //   label: "Fundraising",
      //   link: "/fundraising/setup",
      //   role: 2
      // }
    ],
  },
  {
    label: "Manage",
    icon: 'fa-solid fa-list-check',
    role:-1,
    links: [
      {
        label: "Donations",
        link: "/donations",
        role: 2
      },
      {
        label: "Pledges",
        link: "/pledges",
        role: 2
      },
      {
        label: "Donations",
        link: "admin/donations",
        role: 1
      },
      {
        label: "Recurring Plans",
        link: "/recurring",
        role: 2
      },
      {
        label: "Subscribers",
        link: "/subscribers",
        role: 2
      },
      {
        label: "Donors",
        link: "/donors",
        role: 2
      },
      {
        label: "Yearly Receipts",
        link: "/yearly-receipts",
        role: 2
      },
      {
        label: "Jotform Integration",
        link: "/form-mapping",
        role: 2
      },
      {
        label: "Announcements",
        link: "/announcements",
        role: 1
      },
      // {
      //   label: "Recurring Plans",
      //   link: "/daily-plans",
      //   role: 1
      // },
    ],
  },
  {
    label: "Kiosks",
    icon:'fa-solid fa-tablet-screen-button',
    role:2,
    links: [
      {
        label: "Devices",
        link: "/devices",
        role: 2
      },
      {
        label: "Tiles",
        link: "/tiles",
        role: 2
      },
    ],
  },
  {
    label: "Account",
    icon:'fa-regular fa-credit-card',
    role:2,
    links: [{
      label: "Import Donations",
      link: "/account/import",
      role: 2
    },
    {
      label: "Payment Processors",
      link: "/payment-processors",
      role: 2
    },
    {
      label: "Organization Info",
      link: "/organization",
      role: 2
    }
    ],
  },
];
