import React, { useEffect, useState } from 'react';
import { GET_ALL_DONATIONS, GET_DONATION_STATS } from '../../constants/endpoints';
import DonationStats from './donation-stats';
import DonationTable from './donation-table';
import axios from 'axios';
import { getTimeFilterValue } from '../../utils/utils';
import Loader from '../loader/loader';
import { ADMIN_GET_DONATIONS, ADMIN_GET_DONATION_STATS } from '../../constants/admin-endpoints';

function DonationInfo(props) {

    const [donations, setDonations] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);

    const [donationStats, setDonationStats] = useState({
        total_donations : 0,
        gross_donations: 0,
        average_donation: 0
    });

    //page index for table
    const [pageIndex, setPageIndex] = useState(0);
    const changePage = (pageNumber) => {
        setPageIndex(pageNumber);
        getDonations(pageNumber)
    }

    const getDonations = (pageNumber) =>{
        setDataLoader(true);
        // const query = props?.searchDonationModel ? `?search=${props?.searchDonationModel}` : '';
        let dateTime = {
            startDate: '',
            endDate: ''
        };
        if(props?.timeSearchModel){
                dateTime = getTimeFilterValue(props?.timeSearchModel,props?.datePickerRef?.current?.getValues())
        }
        const filters = {
            text: props?.searchDonationModel,
            startDate: dateTime?.startDate,
            endDate: dateTime?.endDate,
            interval: props?.intervalSearchModel,
            payment_status: props?.paymentStatusSearchModel,
            campaign: props?.campaignSearchModel,
            organization: props?.organizationSearchModel,
            platform_used: props?.donationTypeModel,
            page_index: pageNumber ? pageNumber : 0
        }

        const endpoint = props?.isSuperAdmin ? ADMIN_GET_DONATIONS : GET_ALL_DONATIONS;
        axios.post(endpoint, filters)
        .then((res) => {
            setDataLoader(false);
            if(res?.data?.status){
                setDonations(res?.data?.response);
            }else{
                setDonations([]);
            }
        }).catch((error) => {
            setDataLoader(false);
        });
    }

    useEffect(() => {
        // console.log(props?.datePickerRef?.current?.getValues())
        setPageIndex(0);
        getDonations(0);
        getDonationStats();
    },[
        props?.searchDonationModel,
        props?.campaignSearchModel,
        props?.intervalSearchModel,
        props?.paymentStatusSearchModel,
        props?.timeSearchModel,
        props?.organizationSearchModel,
        props?.donationTypeModel
    ]);

    const getDonationStats = () => {
        let dateTime = {
            startDate: '',
            endDate: ''
        };
        if(props?.timeSearchModel){
                dateTime = getTimeFilterValue(props?.timeSearchModel,props?.datePickerRef?.current?.getValues())
        }
        

        // let query = '';
        // query += `?text=${props?.searchDonationModel}`;
        // query += `&startDate=${dateTime.startDate}`;
        // query += `&endDate=${dateTime.endDate}`;
        // query += `&interval=${props?.intervalSearchModel}`;
        // query += `&campaign=${props?.campaignSearchModel}`;
        const filters = {
            text: props?.searchDonationModel,
            startDate: dateTime?.startDate,
            endDate: dateTime?.endDate,
            interval: props?.intervalSearchModel,
            payment_status: props?.paymentStatusSearchModel,
            campaign: props?.campaignSearchModel,
            organization: props?.organizationSearchModel,
            platform_used: props?.donationTypeModel,
        }

        const endpoint = props?.isSuperAdmin ? ADMIN_GET_DONATION_STATS : GET_DONATION_STATS;
        axios.post(endpoint, filters)
        .then((res) => {
            if(res?.data?.status){
                setDonationStats(res?.data?.response);
            }
        }).catch((error) => {

        })
    }

    return (
        <div>
            {props?.showTitle ?
            <div className='row mt-5'>
                <div className='col-xl-12 text-center bg-white pb-1'>
                    <div className='border-bottom'>
                        <h5 className='mb-2'>Donations</h5>
                    </div>
                </div>
            </div>
            : ''}

            {/* injecting donation stats component */}
            <DonationStats data={donationStats}/>

            {/* injecting donation table component */}

            <DonationTable
            isSuperAdmin={props?.isSuperAdmin}
            dataLoader={dataLoader}
            changePage={changePage}
            pageIndex={pageIndex}
             columns={props?.columns}
              data={donations}/>
        </div>

    );
}

export default DonationInfo;