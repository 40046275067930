export const intervalsList = [
    { label: 'All Intervals', value: '' },
    { label: 'One Time', value: 'One-Time' },
    { label: 'Daily Interval', value: 'Daily' },
    { label: 'Weekly Interval', value: 'Weekly' },
    // {label:'Last year', value: 'biweekly_interval'},
    { label: 'Monthly Interval', value: 'Monthly' },
    { label: 'Quarterly Interval', value: 'Quarterly' },
    { label: 'Annually Interval', value: 'Annually' }
  ];

export const recurringStatusList = [
  {label: 'All Status', value:''},
  {label: 'Active', value:'Active'},
  {label: 'Failed', value:'Failed'},
  {label: 'Paused', value:'Paused'},
  {label: 'Canceled', value:'Canceled'},
];

export const dateFilterList = [
  { label: 'All Time', value: '' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 days', value: 'last_7_days' },
  { label: 'Last 30 days', value: 'last_30_days' },
  { label: 'This month', value: 'this_month' },
  { label: 'Last month', value: 'last_month' },
  { label: 'This year', value: 'this_year' },
  { label: 'Last year', value: 'last_year' }
];

export const paymentTypeList = [
  {label:'Check', value:'check'},
  {label:'Cash', value:'cash'},
  {label:'Credit Card', value:'credit card'},
  {label:'Zelle', value:'zelle'},
  {label:'Paypal', value:'paypal'},
  {label:'External Bank Transfer', value:'bank transfer'}
];

export const emailVariables = [
  { label: 'Select Variables', value: 'select_value' },
  { label: 'Organization Name', value: ' {organization_name}' },
  { label: 'Campaign Name', value: ' {campaign_name}' },
  { label: 'Donor First Name', value: ' {donor_first_name}' },
  { label: 'Donor Last Name', value: ' {donor_last_name}' },
  { label: 'Amount', value: ' {amount}' },
  { label: 'Interval', value: ' {interval}' },
  { label: 'Donation Detail', value: ' {donation_details}' },
  { label: 'Donation Link', value: ' {donation_link}' },
];

export const emailVariablesYearlyReceipt = [
  { label: 'Select Variables', value: 'select_value' },
  { label: 'Organization Name', value: ' {{organization.organization_name}}' },
  { label: 'Donor First Name', value: ' {{first_name}}' },
  { label: 'Donor Last Name', value: ' {{last_name}}' },
  { label: 'Total Amount', value: ' {{total_amount}}' },
];

export const fiscalYearList = [
  {label:'January 1 - December 31 (Calendar Year)', value:'0-01'},
  {label:'January 1 - December 31 (Current Year)', value:'current-year'},
  {label:'April 1 - March 31', value:'3-01'},
  {label:'July 1 - June 30', value:'6-01'},
  {label:'August 1 - July 31', value:'7-01'},
  {label:'October 1 - September 30', value:'9-01'}
];

export const receiptStatusList = [
  {label:'Not Sent', value:'not sent'},
  {label:'Sent', value:'sent'}
];

export const recurringOptionsList = [
  {label:'None', value: 'none'},
  {label:'End by Date', value: 'end_by_date'},
  {label:'Charge fixed number of times', value: 'charge_fixed_number_of_times'},
  {label:'Allow user to select number of times', value: 'allow_user_to_select_number_of_times'},
];

export const campaignFormMappingList = [
  {label: "First Name", value:"first_name", order_value: 1},
  {label: "Last Name", value:"last_name" , order_value: 2},
  {label: "Email", value:"email", order_value: 3},
  {label: "Amount", value:"amount", order_value: 4},
  {label: "Interval", value:"donation_type", order_value: 5},
  {label: "No Of Payments", value:"recurring_option_value", order_value: 6},
  {label: "Address", value:"address", order_value: 7},
  {label: "Phone", value:"phone", order_value: 8},
  {label: "City", value:"city", order_value: 9},
  {label: "State/Province", value:"state_province", order_value: 10},
  {label: "Postal Code", value:"postal_code", order_value: 11}
];

export const paymentStatusList = [
  {label: "Success", value:"success"},
  {label: "Failed", value:"failed"}
];

export const pledgeStatusList = [
  // {label:'Select Pledge Status', value: ''},
  {label: "Unpaid", value:"Un_Paid"},
  {label: "Pending", value:"Pending"},
  {label: "Paid", value:"Paid"},
  {label: "Partially Paid", value:"Partial_Paid"},
  {label: "Canceled", value:"Canceled"},
];

export const KIOSK_LAYOUT_LIST = [
  {label:"Layout 1", value:"layout_1"},
  {label:"Layout 2", value:"layout_2"},
  {label:"Layout 3", value:"layout_3"},
  {label:"Layout 4", value:"layout_4"}
]



export const PROGRAMS_LIST = [

  {
    "program_id": "735e8d7d-a7d8-4a92-8276-7d08a554b9fj",
    "program_masjid_id": "dfsfd-3423-s556dfsd-fsdf",
    "program_campaign_title": "Program 1",
    "program_donation_page_link": null,
    "program_activate_pledge": 0,
    "program_image_location": "2023/09/23/13499282-beb5-45ed-b66c-3bf36c97c283.jpg",
    "program_image_alt_name": "5e5366a4-6309-42b9-966e-f96b798277c8.jpg",
    "program_is_display_campaign_title": 0,
    "program_active_campaign_for_kiosk": 1,
    "program_language": "English",
    "program_goal_amount": "12",
    "program_campaign_order": null,
    "program_primary_color": "#007539",
    "program_google_apple_pay": 1,
    "program_one_time_interval": 0,
    "program_daily_interval": 0,
    "program_weekly_interval": 0,
    "program_biweekly_interval": 0,
    "program_monthly_interval": 0,
    "program_quarterly_interval": 0,
    "program_annaully_interval": 0,
    "program_weekly_jumuah_only": 0,
    "program_recurring_option": "",
    "program_recurring_option_value": "",
    "program_quick_donate": 0,
    "program_is_donor_comments": 0,
    "program_is_comment_required": 0,
    "program_comments_label": "",
    "program_is_delete": 0,
    "program_default_interval": "Every-Jumuah",
    "program_recommended_interval": null,
    "program_is_call_to_action": 0,
    "program_call_to_action_message": "",
    "program_enable_anonymous": 1,
    "program_allow_quick_donate_on_kiosk": 0,
    "program_collect_address": 0,
    "program_collect_phone": 0,
    "program_success_message": "",
    "program_is_redirection_url": 0,
    "program_redirection_url": "",
    "program_is_subtitle": 1,
    "program_subtitle": "Sponsor natural gas that serves to heat both the masjid and water that is used to make wadu.",
    "program_is_display_logo": 1,
    "program_is_header_background_color": 0,
    "program_header_background_color": "#007539",
    "program_is_campaign_icon": 0,
    "program_campaign_icon": "",
    "program_ask_donor_to_subscribe": 0,
    "program_email_request_label": "",
    "program_ask_donor_to_subscribe_default": 0,
    "program_type": "program",
    "program_price_per_unit": "20000",
    "program_reset_count": "monthly",
    "program_reset_count_month": "december",
    "program_last_reset_date": "2023-10-04T12:54:58.250Z",
    "program_sell_after_out_of_stock": 1,
    "program_unit_label": "Unit",
    "program_feature_campaign": 1,
    "program_exclude_donations_for_receipts": 1,
    "program_featured_campaign_desc": "We are currently raising funds for the electricity for our new masjid Give on behalf of yourself and for the reward of those who have passed away. Through this donation, may Allah keep you safe on the Day of Judgement, under His shade, where there is no shade except His shade.",
    "program_created_at": "2023-09-26T17:23:03.000Z",
    "total_subscribers": "7",
    "total_amount": "3,000.00"
  },

]