import React, { useEffect, useRef, useState } from 'react';
import { DONATION_COLUMNS } from '../../../constants/table-columns.constant';
import DonationInfo from '../../../shared/donation-info/donation-info';
import { Dropdown, Form } from 'react-bootstrap';
import axios from 'axios';
import { CAMPAIGNS_NAMES, DOWNLOAD_FILE } from '../../../constants/endpoints';
import { downloadBlob, getTimeFilterValue } from '../../../utils/utils';
import DatePickerDialog from '../../../shared/date-picker-dialog/date-picker-dialog';
import getFormattedDate from '../../../utils/date-format';
import { paymentStatusList } from '../../../data-list/data-list';
// import DonationInfo from '../campaigns/campaing-detail/shared/donations-info';

function Donations(props) {
  const datePickerDialogRef = useRef(null);

  const [searchDonationModel, setSearchDonationModel] = useState('');
  const [campaignSearchModel, setCampaignSearchModel] = useState('');
  const [intervalSearchModel, setIntervalSearchModel] = useState('');
  const [paymentStatusSearchModel, setPaymentStatusSearchModel] = useState('success');
  const [donationTypeModel, setDonationTypeModel] = useState('');
  const [timeSearchModel, setTimeSearchModel] = useState('');

  const [downloadFileLoader, setDownloadFileLoader] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);

  const timesList = [
    { label: 'All Time', value: '' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 7 days', value: 'last_7_days' },
    { label: 'Last 30 days', value: 'last_30_days' },
    { label: 'This month', value: 'this_month' },
    { label: 'Last month', value: 'last_month' },
    { label: 'This year', value: 'this_year' },
    { label: 'Last year', value: 'last_year' },
    { label: 'Custom Date', value: 'custom_date' }
  ];

  const intervalsList = [
    { label: 'All Intervals', value: '' },
    { label: 'One Time', value: 'One-Time' },
    { label: 'Daily Interval', value: 'Daily' },
    { label: 'Weekly Interval', value: 'Weekly' },
    // {label:'Last year', value: 'biweekly_interval'},
    { label: 'Monthly Interval', value: 'Monthly' },
    { label: 'Quarterly Interval', value: 'Quarterly' },
    { label: 'Annually Interval', value: 'Annually' }
  ];

  const getCampaignsName = () => {
    axios.get(CAMPAIGNS_NAMES + 'both')
      .then((res) => {
        if (res?.data?.status) {
          setCampaignsList(res?.data?.response);
        }
      }).catch((error) => {

      });
  };


  const downloadCSV = () => {
    
    setDownloadFileLoader(true);
    const dateTime = timeSearchModel ? getTimeFilterValue(timeSearchModel,datePickerDialogRef?.current?.getValues()) : {
      startDate: '',
      endDate: ''
  };
  const filters = {
      text: searchDonationModel,
      startDate: dateTime?.startDate,
      endDate: dateTime?.endDate,
      interval: intervalSearchModel,
      campaign: campaignSearchModel,
      payment_status: paymentStatusSearchModel
  }
    axios.post(DOWNLOAD_FILE, filters,{ responseType: 'blob' })
    .then((res) => {
      setDownloadFileLoader(false);
      
      if(res?.data instanceof Blob){
        downloadBlob(res?.data);
      }else{
        alert('Failed to download file.');
      }
    }).catch((error) => {
      setDownloadFileLoader(false);
      alert('Failed to download file.');
    })
  }

  useEffect(() => {
    getCampaignsName();
  }, []);

  return (
    <div className='row'>
      <div className='col-xl-12'>
        <div>
          <h5 className='font-weight-semibold text-black'>Donations</h5>
        </div>
      </div>

      <div className="col-xxl-2 col-xl-4 col-lg-6 col-md-6 mt-2">
        <div className="form-group">
          <Form.Select
            value={timeSearchModel?.includes('from') ? 'custom_date' : timeSearchModel}
            onChange={(event) => {
              if(event.target.value !== 'custom_date'){
                datePickerDialogRef?.current?.reset({
                  from:"",
                  to: ""
                })
                setTimeSearchModel(event.target.value)
              }else{
                datePickerDialogRef?.current?.handleShow();
              }
              
            }}
            aria-label="Default select example">
            {
              timesList.map((item, index) => {
                return <option key={item.value + ' ' + index} value={item.value}>{item.label}</option>
              })
            }

          </Form.Select>
          {
            datePickerDialogRef?.current?.getValues()?.from ?
            <p className='f-12-small text-bold'>Custom Range: {getFormattedDate(datePickerDialogRef?.current?.getValues()?.from)} - {getFormattedDate(datePickerDialogRef?.current?.getValues()?.to)} <a onClick={datePickerDialogRef?.current?.handleShow} className='f-12-small cursor-pointer text-ma-primary'>Change Date</a></p> : '' 
          }
          
        </div>
      </div>

      <div className="col-xxl-1 col-xl-4 col-md-6 mt-2">
        <div className="form-group">
          <Form.Select
            value={paymentStatusSearchModel}
            onChange={(event) => setPaymentStatusSearchModel(event.target.value)}>
            {
              paymentStatusList.map((item, index) => {
                return <option key={item.value}
                  value={item.value}>{item.label}</option>
              })
            }
            <option value={""}>Pending</option>
          </Form.Select>
        </div>
      </div>
      <div className="col-xxl-1 col-xl-4 col-md-6 mt-2">
        <div className="form-group">
          <Form.Select
          placeholder='Donation Type'
            value={donationTypeModel}
            onChange={(event) => setDonationTypeModel(event.target.value)}>
            <option value={"all"}>All</option>
            <option value={'offline'}>Offline</option>
            <option value={'stripe'}>Stripe</option>
            <option value={'uploaded'}>Uploaded</option>
          </Form.Select>
        </div>
      </div>
      <div className="col-xxl-2 col-xl-4 col-lg-6 col-md-6 mt-2">
        <div className="form-group">
          <Form.Select
            value={intervalSearchModel}
            onChange={(event) => setIntervalSearchModel(event.target.value)}
            aria-label="Default select example">
            {
              intervalsList.map((item, index) => {
                return <option key={item.value + ' ' + index}
                  value={item.value}>{item.label}</option>
              })
            }
          </Form.Select>
        </div>
      </div>

      <div className="col-xxl-2 col-xl-4 col-lg-6 col-md-6 mt-2">
        <div className="form-group">
          <Form.Select
            value={campaignSearchModel}
            onChange={(event) => setCampaignSearchModel(event.target.value)}
            aria-label="Default select example">
            <option value={""}>All Campaigns</option>
            {
              campaignsList.map((item, index) => {
                return <option
                  key={'campaigns ' + index}
                  value={item.id}>{item.campaign_title}</option>
              })
            }
          </Form.Select>
        </div>
      </div>

      <div className="col-xl-4 col-md-6 mt-2">
        <div className="form-group">
          <div className="input-group">
            {/* <span className="input-group-text text-body">
                      <i className="fas fa-search" aria-hidden="true"></i>
                    </span> */}
            <input
              id="txtDonationSearch"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearchDonationModel(e.target.value);
                }
              }}
              type="text"
              className="form-control"
              placeholder="Search by name email"
            />
            <button onClick={(e) => {
              const value = document.getElementById('txtDonationSearch')?.value;
              setSearchDonationModel(value);
            }} type="button" className="btn btn-outline-secondary mb-0">Search</button>
            <Dropdown>
              <Dropdown.Toggle
                disabled={downloadFileLoader}
                variant="default" id="dropdown-basic" 
                className={`btn btn-outline-secondary mb-0 border-radius-none 
                ${downloadFileLoader ? 'position-relative button--loading' : ''}`}>
                Export
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={downloadCSV}>Export All Columns</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* date picker dialog start */}
      <DatePickerDialog 
      setTimeSearchModel={setTimeSearchModel}
      ref={datePickerDialogRef} />
      {/* date picker dialog end */}

      <div className='col-xl-12'>
        <DonationInfo
          datePickerRef={datePickerDialogRef}
          campaignSearchModel={campaignSearchModel}
          intervalSearchModel={intervalSearchModel}
          paymentStatusSearchModel={paymentStatusSearchModel}
          donationTypeModel={donationTypeModel}
          timeSearchModel={timeSearchModel}
          showTitle={false}
          searchDonationModel={searchDonationModel}
          columns={DONATION_COLUMNS} />
      </div>
    </div>
  );
}

export default Donations;