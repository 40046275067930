import React, { useRef } from 'react';
// import CampaignForm from './shared/campaign-form';
import "../../campaigns/campaign-form/campaign-form.scss"
// import CampaignFormView from './shared/campaign-form-view';
import SharedFormPreviewComponent from '../../../../shared/shared-form-preview/shared-form-preview.component';
import ProgramsForm from './programs-form';
// import SharedFormPreviewComponent from '../../../../shared/shared-form-preview/shared-form-preview.component';

function ProgramsMain(props) {

    const programsFormRef = useRef(null);

    const previewPrograms = () =>{
        if(programsFormRef?.current?.previewChanges){
            programsFormRef.current.previewChanges();
        }
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
            <ProgramsForm ref={programsFormRef} />
            <SharedFormPreviewComponent
            triggerEvent={previewPrograms}
            isSubtitle={true}
            image={'../../../img/masjid/product_form.png'}/>
            </div>
            
        </div>
    );
}

export default ProgramsMain;