import React from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../../../shared/fields-errors/field-error';

const DonorCommentsForm = React.forwardRef((props, ref) => {

    const { register, watch, formState: { errors } } = useFormContext();
    const fieldsWatcher = watch();

    return (
        <div className='card col-12 mt-3'>
            <div className='card-body'>
                <label className="form-control-label text-black text-bold text-capitalize" >
                    {props?.name ? props?.name : "Donor"} Comments
                </label>

                <div className='form-group mt-3'>
                    <label className="form-control-label d-flex align-items-center">
                        <input {...register("is_donor_comments")} className="me-1" type="checkbox" /> Allow {props?.name ? props?.name : "donor"} to enter comments
                    </label>
                </div>
                {
                    fieldsWatcher?.is_donor_comments === true ?
                        <>
                            <div className='form-group mt-3'>
                                <label className="form-control-label">
                                    <span className='text-danger'>*</span> Label For Comments
                                </label>
                                <input {...register("comments_label", {
                                    validate: {
                                        required: (value) => {
                                            if (!value) return "This field is required";
                                            return true
                                        }
                                    }
                                })} placeholder="Label for comments" type="text" className="form-control-alternative form-control" />
                                {errors?.comments_label ?
                                    <FieldError message={errors?.comments_label?.message} /> : ''}
                            </div>
                            <div className='form-group mt-3'>
                                <label className="form-control-label d-flex align-items-center">
                                    <input {...register("is_comment_required")} className="me-1" type="checkbox" /> Make {props?.name ? props?.name : "donor"} comments required.
                                </label>
                            </div>
                        </>
                        : ""
                }

                
            </div>
        </div>
    );
});

export default DonorCommentsForm;