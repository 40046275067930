import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import "../donation-widget/donation-widget.scss";
import "./product-donation-widget.scss";
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
import { GET_PRODUCT_BY_ID, GET_PRODUCT_BY_LINK, GET_RAISED_DONATIONS } from '../../../constants/endpoints';
import { FormProvider, useForm } from "react-hook-form";
import PaymentRequestForm from '../../../shared/payment-request-form/payment-request-product.form';
import { Elements } from '@stripe/react-stripe-js';
import DonorUserInfo from '../donation-widget/donor-user-info';
import calculateCCAfterFee from '../../../utils/stripe-payment-fee.util';
import { getTextColorByBG } from '../../../utils/utils';
import getFormattedDateTime from '../../../utils/date-time-format';
import PaymentForm from '../donation-widget/payment-form-product';
import FieldError from '../../../shared/fields-errors/field-error';
import { ENVIRONMENT_CONSTANT } from '../../../constants/env.constant';
import DonorCommentsTemplate from '../donation-widget/donor-comments-template';
import { CURRENCY_CODE_SIGN_MAPPING } from '../../../constants/currency-code-sign.constant';

const publishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
function ProductDonationWidget(props) {
    const [stripeButtonElementLoader, setStripeButtonElementLoader] = useState(false);
    //props start
    const requestFormRef = useRef(null);
    const logoBaseUrl = ENVIRONMENT_CONSTANT.LOGO_BASE_URL;
    const [raisedDonations, setRaisedDonations] = useState({});
    const [productDetail, setProductDetail] = useState({});
    const [amount, setAmount] = useState(100);
    const [paymentFormAmount, setPaymentFormAmount] = useState(0);
    const [formStep, setFormStep] = useState('amount-selection');
    const [isGoogleApplePay, setIsGoogleApplePay] = useState(false);
    const [unitPrice, setUnitPrice] = useState(0);
    const [stripePromise, setStripePromise] = useState(null);

    //stripe appearance settings 
    const appearance = {
        theme: 'stripe',
    };
    //stripe settings to load widget
    const [options, setOptions] = useState({
        mode: 'payment',
        amount,
        currency: 'usd',
        paymentMethodCreation: 'manual',
        // Fully customizable with appearance API.
        appearance: appearance,
    });

    //getting url params
    const params = useParams();
    //saving product id from params
    // const productID = params.id;
    const link = params.link;
    const short_code = params.short_code;

    //initializing for react form
    const methods = useForm();
    //getting different props from react forms
    const { register, trigger, handleSubmit, setValue, reset, clearErrors,
        unregister,
         watch, formState: { isValid, errors }, getValues } = methods;
    
    //watching form fields 
    const fieldsWatcher = watch();
    //props end

    //useEffect implementation start
    useEffect(() => {
        if(short_code){
            getProductInfo();
        }
    }, [short_code])


    //component functions start

    //getting raised donations from db
    const getRaisedDonation = async (product) => {
        axios.post(GET_RAISED_DONATIONS, product)
            .then((res) => {
                if (res?.data?.success) {
                    setRaisedDonations(res?.data?.response)
                }
            }).catch((error) => {

            })
    }

    //getting product detail from api
    const getProductInfo = async () => {
        axios.get(`${GET_PRODUCT_BY_LINK}${link}/${short_code}`)
            .then(async (res) => {
                if (res?.data?.status) {
                    //setting up global variables for stripe processing fee
                    window.stripe_flat_fee = parseFloat(res?.data?.response?.stripe_flat_fee);
                    window.stripe_percentage_fee = (parseFloat(res?.data?.response?.stripe_percentage_fee) / 100).toFixed(3);
                    
                    // res.data.response.donation_amount = {
                    //     ask_donors_cover_fee_default: res.data.response.ask_donor_to_cover_fee_default,
                    //     ask_donors_cover_fee: res.data.response.ask_donor_to_cover_fee,
                    // }
                    setProductDetail(res?.data?.response);
                    setValue('no_of_units', 1);
                    setAmount((res?.data?.response?.price_per_unit * 1) * 100);
                    setPaymentFormAmount(res?.data?.response?.price_per_unit * 1);
                    setUnitPrice(res?.data?.response?.price_per_unit);
                    //loading stripe start
                    if (res?.data?.response?.stripe_account_id) {
                        const stripePromise = await loadStripe(publishKey, {
                            stripeAccount: res?.data?.response?.stripe_account_id
                        });

                        setStripePromise(stripePromise)
                    }
                    //loading stripe end

                    if (parseFloat(res?.data?.response?.goal_amount)) getRaisedDonation(res?.data?.response);

                } else {
                    alert(res?.data?.message);
                    window.location.href = `${window.location.origin}/${short_code}`;
                }
            }).catch((error) => {
                alert('Unable to get information regarding this product');
            })
    }

    const returnSelectedAmount = () => {
        return parseFloat(paymentFormAmount)
    }

    //toggling quantity start
    const toggleQuantity = (type) => {
        let currentQuantityValue = getValues()?.no_of_units;
        if(type === 'increment'){
            if(!productDetail?.sell_after_out_of_stock && productDetail?.available_units){
                if(productDetail?.available_units > currentQuantityValue){
                    currentQuantityValue++
                }
            }else{
                currentQuantityValue++
            }
            
        }else{
            if(currentQuantityValue > 1){
                currentQuantityValue--
            }
        }

        setValue('no_of_units', currentQuantityValue);
        setAmount(((unitPrice)*currentQuantityValue) * 100);
        setPaymentFormAmount((unitPrice)*currentQuantityValue);
    }
    //toggling quantity end

    const setForm = (value) => {
        if (typeof (value) === 'boolean') {
            setIsGoogleApplePay(value);
        } else {
            setFormStep(value);
        }
    }

    //checking if form is valid to proceed next and previous start
    const checkIsFormValidToProceed = () => {
        if (formStep === 'amount-selection') {
            // chooseAmountValidation();
            productDetail?.google_apple_pay && isGoogleApplePay ?
            setFormStep('request_button') : setFormStep('info');
        } else if (formStep === 'info') {
            if (!isValid) {
                trigger();
            } else {
                clearErrors();
                setFormStep('payment');
            }
        } else if (formStep === 'request_button') {
            setFormStep('info');
        }
    }
    //checking if form is valid to proceed next and previous end

    // confirmation screen or redirect after payment
    const onPaymentSuccess = (message) => {
        if (productDetail?.is_redirection_url && productDetail?.redirection_url) {
            const redirectURL =
                (productDetail?.redirection_url?.includes('http') || productDetail?.redirection_url?.includes('https')) ?
                    productDetail?.redirection_url : 'https://' + productDetail?.redirection_url;
                    window.open(redirectURL, '_self');
        } else {
            setFormStep('confirmation')
        }
    }
    
    //validating donor info screen
    const validateDonorInfo = () => {
        if (isValid) {
            if(!requestFormRef?.current?.removeFeeForACH && productDetail?.donation_amount?.ask_donors_cover_fee_default){
                setValue('cover_processing_fee', true);
                const amount = returnSelectedAmount();
               requestFormRef?.current?.setFinalAmount(parseFloat(calculateCCAfterFee(amount)));
               setAmount(Math.round(parseFloat(calculateCCAfterFee(amount)) * 100));
            }else{
                setValue('cover_processing_fee', false);
                requestFormRef?.current?.setFinalAmount(returnSelectedAmount());
                setAmount(Math.round(returnSelectedAmount() * 100));
            }
            setFormStep('payment');
        } else {
            trigger();
        }
    }

    //returning final payload for donation
    const [finalScreenAmount, setFinalScreenAmount] = useState(0);
    const returnDonationPayload = (formData) => {
        const donationPayload = {
            campaign_id: productDetail?.donation_amount?.campaign_id,
            donation_type: 'One-Time',
            campaign_title: productDetail?.campaign_title,
            amount: paymentFormAmount,
            masjid_id: productDetail?.masjid_id,
            stripe_account_id: productDetail?.stripe_account_id,
            return_url: window.location.origin,
            ...getValues(),
            ...(formData ? formData : {})
        }

        //replacing amount based on stripe fee
        if (donationPayload?.cover_processing_fee) {
            const finalAmount = parseFloat(calculateCCAfterFee(donationPayload.amount));
            donationPayload.stripe_processing_fee = (parseFloat(finalAmount) - donationPayload.amount).toFixed(2);
            donationPayload.amount = finalAmount;
        } else {
            donationPayload.stripe_processing_fee = 0;
        }
        setFinalScreenAmount((donationPayload.amount)?.toFixed(2))
        // setAmount(donationPayload.amount);
        //after replacing amount and setting up stripe fee returning payload to child
        console.log(donationPayload)
        return donationPayload;
    }

    const getDonationPercentage = () => {
        const percentage = ((raisedDonations?.total_unit_purchased / productDetail?.goal_amount) * 100);
        return (percentage > 100 ? 100 : percentage) + '%';
    }

    //register and unregister the processing fee checkbox
    useEffect(() => {
        if (formStep === 'payment' || formStep === 'amount-selection') {
            register('cover_processing_fee');
        } else {
            unregister('cover_processing_fee');
        }
    }, [formStep])
    //component functions end

    useEffect(() => {
        if(formStep === 'request_button'){
            if(!productDetail?.donation_amount?.ask_donors_cover_fee && productDetail?.donation_amount?.ask_donors_cover_fee_default){
                register("cover_processing_fee", {
                    value: true
                });
            }
        }
    }, [formStep])

    return (
        <>
            {
                productDetail?.is_delete === true ?
                    <div className='container'>
                        <div className='mt-5 col-10 offset-1' style={{ backgroundColor: 'aliceblue' }}>
                            <div className='row text-center'>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-xl-10 mt-lg-7 mt-md-3 mt-sm-3 mt-3 p-5'>
                                    <h4>Oops!</h4>
                                    <p className='mt-4'>We’ve tried searching but couldn’t find the page you’re looking for.</p>
                                    <p className='mt-4'>Not to worry — let’s find a better place for you to go.</p>
                                    <p className='mt-4'>
                                        Are you a Masjidal organization looking for your campaign? click <a className='text-light-blue' href='https://donation.dev.masjidal.com/'>here</a>.</p>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                                    <img src='../../../img/404.jpg' alt='404' className='w-100' />
                                </div>
                            </div>
                        </div>

                    </div> : productDetail?.donation_amount?.id ? <FormProvider {...methods}>
                        {
                            productDetail?.is_display_logo || !productDetail?.is_display_campaign_title
                                || productDetail?.is_subtitle ?
                                <div className='custom-page-header'>
                                    {
                                        productDetail?.is_display_logo && productDetail?.image_location ?
                                            <div className='col-12 mt-3'>
                                                <img className='donation-widget-logo' src={`${logoBaseUrl}${productDetail?.image_location}`} alt="Masjid Image" />
                                            </div> : ''
                                    }


                                    {/* {
                                        !productDetail?.is_display_campaign_title ?
                                            <div className='col-12 mt-3'>
                                                <span style={{
                                                    color:
                                                        (productDetail?.is_header_background_color ?
                                                            getTextColorByBG(productDetail?.header_background_color) : 'black')
                                                }} className='custom-header-title'>{productDetail?.campaign_title}</span>
                                            </div> : ''
                                    }

                                    {
                                        productDetail?.is_subtitle ?
                                            <div className='col-12 mt-2 mb-4'>
                                                <span style={{
                                                    color:
                                                        (productDetail?.is_header_background_color ?
                                                            getTextColorByBG(productDetail?.header_background_color) : 'black')
                                                }} className='custom-header-subtitle'>{productDetail?.subtitle}</span>
                                            </div> : ''
                                    } */}

                                </div> : ''
                        }


                        {
                            productDetail?.is_header_background_color ?
                                <div style={{ backgroundColor: productDetail?.header_background_color }} className='form--header'></div>
                                : ''
                        }


                        <form >
                            {
                                <div className='container'>
                                    <div className='col-xl-4 col-lg-6 col-md-8 col-sm-12 col-xs-12 offset-md-2 offset-xl-4 offset-lg-3'>

                                        {
                                            parseFloat(productDetail?.goal_amount) && formStep !== 'confirmation' ?
                                                <div className='row mt-3 position-relative'>
                                                    <div className='col-12'>
                                                        <div className='donation-widget'>
                                                            <div className='donation'>
                                                            {/* style={{ width: getDonationPercentage() }} */}
                                                                <div className='donation__received' style={{ 
                                                                    width: getDonationPercentage(),
                                                                    backgroundColor: productDetail?.primary_color ? (productDetail?.primary_color) : '#015B97'
                                                                 }}></div>
                                                            </div>

                                                            <div className='d-flex justify-content-between align-items-center p-4'>
                                                                <div className='text-center d-grid'>
                                                                    <span className='text-black'>{raisedDonations?.total_unit_purchased}</span>
                                                                    <span className='text-black'>Raised</span>
                                                                </div>
                                                                <div className='text-center d-grid'>
                                                                    <span className='text-black'>{raisedDonations?.donations}</span>
                                                                    <span className='text-black'>Donations</span>
                                                                </div>
                                                                <div className='text-center d-grid'>
                                                                    <span className='text-black'>{productDetail?.goal_amount}</span>
                                                                    <span className='text-black'>Goal</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> : ''
                                        }

                                        <div className="card mt-3 donation-header">
                                            <div className="border-0 card-header donation-header" style={{
                                                backgroundColor: productDetail?.primary_color ? (productDetail?.primary_color) : '#005B97'
                                            }}>
                                                <div className="align-items-center row">
                                                    <div className="col-12 text-left">
                                                        <div className="mb-0 text-white d-flex justify-content-between align-items-center">

                                                            <div>
                                                                {(formStep === 'info' || formStep === 'payment' || formStep === 'request_button') && 
                                                                (!productDetail?.sell_after_out_of_stock ? 
                                                                    (productDetail?.available_units === undefined || productDetail?.available_units > 0) ? true : false : true)  ?
                                                                    <span onClick={(() => setFormStep(
                                                                        (formStep === 'info' && isGoogleApplePay && productDetail?.google_apple_pay) ? 'request_button' :
                                                                            formStep === 'info' ? 'amount-selection' :
                                                                                formStep === 'request_button' ? 'amount-selection' : 'info'
                                                                    ))} className="f-22 cursor-pointer fa-solid fa-arrow-left me-5"></span> : ''
                                                                }

                                                                <span className='f-22'>
                                                                    {formStep === 'amount-selection' ? 'Choose Quantity' :
                                                                        formStep === 'info' ? 'Information' :
                                                                            formStep === 'request_button' ? 'Payment' :
                                                                                formStep === 'payment' ?
                                                                                    'Payment' : 'Success'}
                                                                </span>
                                                            </div>
                                                            {
                                                                formStep !== 'confirmation' ?
                                                                    <span>
                                                                        <i className={`fa-circle me-2 ${formStep === 'amount-selection' ? 'fa-solid' : 'fa-regular'}`}></i>
                                                                        {isGoogleApplePay ? <i className={`fa-circle me-2 ${formStep === 'request_button' ? 'fa-solid' : 'fa-regular'}`}></i> : ''}
                                                                        <i className={`fa-circle me-2 ${formStep === 'info' ? 'fa-solid' : 'fa-regular'}`}></i>
                                                                        <i className={`fa-circle me-2 ${formStep === 'payment' ? 'fa-solid' : 'fa-regular'}`}></i>
                                                                    </span> : ''
                                                            }
                                                            {
                                                                (formStep !== 'confirmation' && formStep !== 'payment' &&
                                                                (!productDetail?.sell_after_out_of_stock ? 
                                                                    (productDetail?.available_units === undefined || productDetail?.available_units > 0) ? true : false : true) 
                                                                ) ?
                                                                    <i onClick={checkIsFormValidToProceed} className="f-24 float-end cursor-pointer fa-solid fa-arrow-right me-2"></i> :
                                                                    ''
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body main-widget p-4">

                                                {/* moving title and subtitle here */}
                                                {
                                                    !productDetail?.is_display_campaign_title && formStep === 'amount-selection' ?
                                                        <div className='col-12 text-center'>
                                                            <h5 className='custom-header-title'>{productDetail?.campaign_title}</h5>
                                                        </div> : ''
                                                }

                                                {
                                                    productDetail?.is_subtitle && formStep === 'amount-selection'?
                                                        <div className='col-12 mt-2 mb-3 text-center text-black'>
                                                            <span className='custom-header-subtitle'>{productDetail?.subtitle}</span>
                                                        </div> : ''
                                                }
                                                <div className={formStep !== 'amount-selection' ? 'd-none' : ''}>
                                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                                        {/* <h5 className='text-center'>Unit Price: ${productDetail?.price_per_unit}</h5> */}
                                                        <p className='d-flex text-black align-items-center justify-content-center text-uppercase'>
                                                            <span>{CURRENCY_CODE_SIGN_MAPPING[productDetail?.currency]}</span> 
                                                            <span className='f-28 mx-1'>{productDetail?.price_per_unit}</span> <span className='f-28'>/</span> <span className='mx-1'>{productDetail?.unit_label ? productDetail?.unit_label : 'Unit'}</span></p>
                                                            
                                                            {
                                                                productDetail?.sell_after_out_of_stock || 
                                                                (productDetail?.available_units === undefined || productDetail?.available_units > 0) ? 
                                                                <>
                                                                <p className='text-center text-black text-bold mb-1'>Qty</p>
                                                                <div className="quantity text-center mb-3">
                                                                <input inputMode='numeric' style={{
                                                                    position:'absolute',
                                                                    left:'0'
                                                                }} type="number"
                                                                    {...register('no_of_units', {
                                                                        value: 1,
                                                                        onBlur: (event) => {
                                                                            const isValidNumber = Number.isInteger(parseFloat(event?.target?.value));
                                                                            if(parseFloat(event?.target?.value) >= productDetail?.available_units){
                                                                                setValue('no_of_units', productDetail?.available_units);
                                                                                setAmount(((unitPrice)*productDetail?.available_units) * 100);
                                                                            setPaymentFormAmount((unitPrice)*productDetail?.available_units);
                                                                            }else if(!isValidNumber || parseFloat(event?.target?.value) <= 0){
                                                                                setValue('no_of_units', 1);
                                                                                setAmount(((unitPrice)*1) * 100);
                                                                                setPaymentFormAmount((unitPrice)*1);
                                                                                return;
                                                                            }else{
                                                                                setValue('no_of_units', parseFloat(event?.target?.value));
                                                                                setAmount(((unitPrice)*parseFloat(event?.target?.value)) * 100);
                                                                                setPaymentFormAmount((unitPrice)*parseFloat(event?.target?.value));
                                                                            }
                                                                        }
                                                                    })}
                                                                    step="1" min="1" max="" title="Qty" className="input-text qty text" size="4" />
                                                                <div className="_qty">
                                                                    <a style={{
                                                                        position:'absolute'
                                                                    }} className="minus cursor-pointer" onClick={() => toggleQuantity('decrement')}>-</a>
                                                                    <a style={{
                                                                        position:'absolute',
                                                                        left:'80%'
                                                                    }} className="plus cursor-pointer" onClick={() => toggleQuantity('increment')}>+</a>
                                                                </div>
                                                            </div>
                                                                </>
                                                                 : ""
                                                            }
                                                       
                                                        {
                                                            productDetail?.available_units > 0 && !productDetail?.sell_after_out_of_stock ? 
                                                            <p className='text-center mb-0 f-xsmal'>{productDetail?.available_units} available</p> : ''
                                                        }
                                                        
                                                        {
                                                            productDetail?.sell_after_out_of_stock || 
                                                            (productDetail?.available_units === undefined || productDetail?.available_units > 0) ? 
                                                            <div className='mt-3 p-1 w-35 text-center d-flex flex-column align-items-center total-unit-purchased'>
                                                            <div className='d-flex justify-content-center flex-row align-item-center mt-1'>
                                                            <span className='me-1'>{CURRENCY_CODE_SIGN_MAPPING[productDetail?.currency]}</span><span className='f-28 text-black'>{(paymentFormAmount)}</span>
                                                            </div>
                                                            <p className='mb-0'>TOTAL</p>
                                                        </div> : ''
                                                        }
                                                        
                                                        {/* donor comments section  */}
                                                        {
                                                            productDetail?.is_donor_comments ? 
                                                            <DonorCommentsTemplate
                                                             settings={productDetail} /> : ""
                                                        }
                                                        {/* <h6 className='text-center mt-2'>Total: ({productDetail?.price_per_unit} x {getValues().no_of_units}) = ${amount}</h6> */}
                                                    </div>

                                                    {
                                                        !productDetail?.sell_after_out_of_stock && 
                                                        productDetail?.available_units <= 0 ? 
                                                        <div className='text-center'>

                                                            <p className='text-bold text-center mb-0 text-danger mt-2'>This product is sold out</p> 
                                                            <p className='text-center mb-0'>If you are willing to donate, you can visit our
                                                            <a className='mx-1 text-cobalt-light text-bold' 
                                                            href={`/masjid/store/${productDetail?.masjid_id}`}>products</a>page
                                                            </p>
                                                            <button  onClick={(() => {
                                                                window.open(productDetail?.website ? productDetail?.website : 'https://mymasjidal.com/', '_self', '');
                                                            })}
                                                                    style={{
                                                                        backgroundColor: productDetail?.primary_color ? (productDetail?.primary_color) : '#005B97',
                                                                        borderBottom: productDetail?.primary_color ? ('2px solid ' + productDetail?.primary_color) : ''
                                                                    }}
                                                                    type="button" className={`btn btn-md w-75 mt-2 text-white btn-step-one`}>
                                                                    <span>
                                                                        <span className='f-18 mx-4'>Close</span>
                                                                    </span>
                                                                </button>
                                                        </div>
                                                         : 
                                                        <div className="row mt-1">
                                                        <div className="col-lg-12 mt-4">
                                                            <div className="text-center position-relative">
                                                                <button
                                                                disabled={stripeButtonElementLoader}
                                                                 onClick={async () => {
                                                                    const isDonorComments = await trigger('donor_comments');
                                                                    if(isDonorComments){
                                                                        if(productDetail?.google_apple_pay && isGoogleApplePay){
                                                                            setFormStep('request_button')    
                                                                        }else{
                                                                            setFormStep('info');
                                                                        }
                                                                    }
                                                                    
                                                                    // productDetail?.google_apple_pay && isGoogleApplePay ?
                                                                    // setFormStep('request_button') : setFormStep('info');
                                                                }}
                                                                    style={{
                                                                        backgroundColor: productDetail?.primary_color ? (productDetail?.primary_color) : '#005B97',
                                                                        borderBottom: productDetail?.primary_color ? ('2px solid ' + productDetail?.primary_color) : ''
                                                                    }}
                                                                    type="button" className={`btn btn-md w-75 text-white btn-step-one`}>
                                                                    <span>
                                                                        <span className='f-18 mx-4'>Next</span> <i className="fa-solid fa-arrow-right f-18"></i>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                   
                                                </div>

                                                <div className={formStep !== 'request_button' || !productDetail?.google_apple_pay ? 'd-none' : ''}>

                                                    {/*  implementing new logic for stripe button element start*/}
                                                    <Elements stripe={stripePromise}>
                                                        <PaymentRequestForm
                                                        setStripeButtonElementLoader={setStripeButtonElementLoader}
                                                        formStep={formStep}
                                                            selectedDonationType={'One-Time'}
                                                            onPaymentSuccess={onPaymentSuccess}
                                                            returnDonationPayload={returnDonationPayload}
                                                            setPaymentFlow={setForm}
                                                            options={options}
                                                            amount={paymentFormAmount}
                                                            campaignDetail={productDetail}
                                                        />
                                                    </Elements>

                                                    {/*  implementing new logic for stripe button element end*/}
                                                </div>
                                                <div className={formStep !== 'info' ? 'd-none' : ''}>
                                                    <DonorUserInfo
                                                        errors={errors}
                                                        donorInfo={validateDonorInfo}
                                                        campaignDetail={productDetail} />
                                                </div>

                                                <div className={`col-12 ${formStep !== 'payment' ? 'd-none' : ''}`}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/visa.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/master.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/amex.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/discover.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/jcb.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                        <div className="me-1">
                                                            <img src="../../img/masjid/union_pay.png" className='w-100' alt="Stripe Logo" />
                                                        </div>
                                                    </div>

                                                    {/* render payment form start */}

                                                    <div className="mt-4">
                                                        {
                                                            productDetail?.id ?
                                                                <Elements stripe={stripePromise} options={{
                                                                    mode: 'payment',
                                                                    amount,
                                                                    currency: productDetail?.currency,
                                                                    paymentMethodCreation: 'manual',
                                                                    // Fully customizable with appearance API.
                                                                    appearance: appearance,
                                                                }}>
                                                                    {/* adding payment options for card and google pay apple pay */}
                                                                    <PaymentForm
                                                                        ref={requestFormRef}
                                                                        formStep={formStep}
                                                                        amount={amount}
                                                                        paymentFormAmount={paymentFormAmount}
                                                                        setAmount={setAmount}
                                                                        formValues={getValues()}
                                                                        fieldsWatcher={fieldsWatcher}
                                                                        returnSelectedAmount={returnSelectedAmount}
                                                                        onPaymentSuccess={onPaymentSuccess}
                                                                        selectedDonationType={''}
                                                                        campaignDetail={productDetail}
                                                                        returnDonationPayload={returnDonationPayload}
                                                                        clientSecret={options?.clientSecret}
                                                                    />
                                                                </Elements> : ''
                                                        }
                                                        
                                                    </div>
                                                    {/* render payment form end */}
                                                </div>

                                                <div className={`row ${formStep !== 'confirmation' ? 'd-none' : ''}`}>
                                                    <div className="col-lg-12 text-center">
                                                        <i className="fa-regular fa-circle-check" style={{ fontSize: '5rem', color: '#52a355' }}></i>
                                                        <h5 className='mt-3 mb-0'>You Have {productDetail?.donation_amount?.change_label_to_pay ? 'Paid' : 'Donated'} ${
                                                            finalScreenAmount
                                                        }</h5>
                                                        <span>{new Date().toDateString().split(" ")[0] + ' ' + getFormattedDateTime(new Date())}</span>
                                                        <p className='mt-4 mb-0 text-black'>
                                                            {
                                                                productDetail?.success_message ?
                                                                    productDetail?.success_message :
                                                                    'Thank you for your donation.'
                                                            }
                                                        </p>

                                                        <p className='text-black'>A receipt has been emailed to you.</p>

                                                        <button
                                                             style={{
                                                                        backgroundColor: productDetail?.primary_color ? (productDetail?.primary_color) : '#005B97',
                                                                        borderBottom: productDetail?.primary_color ? ('2px solid ' + productDetail?.primary_color) : ''
                                                                    }}
                                                            onClick={(() => {
                                                                window.open(productDetail?.website ? productDetail?.website : 'https://mymasjidal.com/', '_self', '');
                                                            })}
                                                            type="button" className={`btn text-white btn-md w-70 `}>
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>



                                                <div className='text-center anchor mt-3'>
                                                    <a href='https://masjidal.com/' target='_blank'>Powered by Masjidal</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className='text-center mt-3'>{productDetail?.donation_amount?.compliance}</p>
                                </div>

                            }
                        </form>
                    </FormProvider> : ''
            }


        </>


    );
}

export default ProductDonationWidget;